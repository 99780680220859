<template>
	<div ref="login">
		<h2 class="font-weight-bold my-4 text-capitalize">{{ $t('login') }}</h2>
		<v-form @submit.stop.prevent="onSubmit">
			<v-alert v-show="errors" dark color="pink darken-2">
				{{ errors }}
			</v-alert>
			<v-row>
				<v-col>
					<v-text-field
						ref="email"
						prepend-inner-icon="fa-user"
						name="userName"
						placeholder="Email"
						outlined
						hide-details="auto"
						:error-messages="emailErrors()"
						:error="$v.form.userName.$anyError"
						v-model="$v.form.userName.$model"
					></v-text-field>
				</v-col>
			</v-row>
			<v-row class="mb-2">
				<v-col>
					<v-text-field
						ref="password"
						prepend-inner-icon="fa-lock"
						name="userPassword"
						v-model="$v.form.userPassword.$model"
						placeholder="Password"
						outlined
						hide-details="auto"
						:append-icon="passwordVisibility ? 'fa-eye' : 'fa-eye-slash'"
						:type="passwordVisibility ? 'password' : 'text'"
						:error-messages="passwordErrors()"
						:error="$v.form.userPassword.$anyError"
						@click:append="() => (passwordVisibility = !passwordVisibility)"
					></v-text-field>
				</v-col>
			</v-row>
			<!-- <v-flex d-sm-flex d-block align-center mb-4 mb-sm-0 text-capitalize>
				<div class="ml-auto my-1">
					<router-link
						:class="{ 'theme--light': !$vuetify.theme.dark }"
						class="text-decoration-none v-label"
						:to="{ name: 'forgetPassword' }"
						>{{ $t('forgetPassword') }}?</router-link
					>
				</div>
			</v-flex> -->
			<v-btn
				ref="login"
				:disabled="$v.form.$error && $v.form.$invalid"
				:class="{ 'white--text': !$vuetify.theme.dark }"
				class="mr-3"
				type="submit"
				color="primary"
				>{{ $t('login') }}</v-btn
			>
			<v-btn @click="resetForm">{{ $t('clear') }}</v-btn>
		</v-form>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { LOGIN, LOGOUT, SET_ERROR } from '@/store/modules/auth.module';
import { SET_THEME } from '@/store/modules/sideBar.module';
import { validationMixin } from 'vuelidate';
import { email, minLength, required } from 'vuelidate/lib/validators';
import { getTimezone } from '@/common/utils';

export default {
	mixins: [validationMixin],
	name: 'login',
	data() {
		return {
			passwordVisibility: true,
			form: {
				userName: null,
				userPassword: null,
			},
		};
	},
	validations: {
		form: {
			userName: {
				required,
				email,
			},
			userPassword: {
				required,
				minLength: minLength(3),
			},
		},
	},
	mounted() {
		this.$nextTick(() => {
			document.addEventListener('keypress', this.login);
			this.$refs.email.focus();
			this.$refs.password.focus();
			this.$refs.login.focus();
		});
	},
	beforeDestroy() {
		document.removeEventListener('keypress', this.login);
	},
	methods: {
		login(event) {
			if (event.keyCode === 13) {
				this.onSubmit();
			}
		},

		emailErrors() {
			const errors = [];
			const { $dirty, required, email } = this.$v.form.userName;
			if (!$dirty) return errors;
			// eslint-disable-next-line no-unused-expressions
			!required && errors.push('E-Mail is required.');
			// eslint-disable-next-line no-unused-expressions
			!email && errors.push('E-Mail format is not valid.');
			return errors;
		},
		passwordErrors() {
			const errors = [];
			const { $dirty, required, minLength, $params } = this.$v.form.userPassword;
			if (!$dirty) return errors;
			// eslint-disable-next-line no-unused-expressions
			!required && errors.push('Password is required.');
			// eslint-disable-next-line no-unused-expressions
			!minLength && errors.push(`Password should be min ${$params.minLength.min} character!`);
			return errors;
		},
		resetForm() {
			this.form = {
				userName: null,
				userPassword: null,
			};
			this.$store.dispatch(SET_THEME, this.$vuetify.theme.dark);
			this.$nextTick(() => {
				this.$v.$reset();
			});
		},
		onSubmit() {
			this.$v.form.$touch();
			if (this.$v.form.$anyError) {
				return;
			}

			const userName = this.$v.form.userName.$model;
			const userPassword = this.$v.form.userPassword.$model;
			const userTimezone = getTimezone();

			this.$store.dispatch(LOGOUT);
			setTimeout(() => {
				this.$store
					.dispatch(LOGIN, {
						userName,
						userPassword,
						userTimezone,
					})
					.then(() => {
						this.$router.push({
							name: 'dashboard',
						});
						setTimeout(() => {}, 1000);
					});
			}, 500);
		},
	},
	computed: {
		...mapState({
			errors: (state) => state.auth.errors,
		}),
	},
	destroyed() {
		this.$store.commit(SET_ERROR, null);
	},
};
</script>

<style lang="scss" scoped></style>
